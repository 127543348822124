import React from 'react';
import axios from 'axios';

import InnerHTML from 'dangerously-set-html-content';

export default class Support extends React.Component {
  state = {
    content: '<div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>'
  }

  componentDidMount() {
    axios.get(process.env.REACT_APP_PROXY_PATH+`/proxy.php?slug='support'`)
      .then(res => {
        const content = res.data;
        this.setState({ content });
      })
  }

  render() {
    return (
      <div className="page-template-default page page-id-70 fl-builder fl-theme-builder-header fl-theme-builder-part fl-theme-builder-footer fl-theme-builder-singular fl-framework-bootstrap-4 fl-preset-default fl-full-width fl-search-active fl-builder-breakpoint-large">
        <div className="fl-page">
          <div id="fl-main-content" className="fl-page-content" itemProp="mainContentOfPage" role="main">
            <InnerHTML html={this.state.content} />
          </div>
        </div>
      </div>
    )
  }
}