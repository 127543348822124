import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { store } from './app/store';
import { Provider } from 'react-redux';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import BasePage from './pages/base';
import Support from "./pages/support";
import Education from "./pages/education";
import Podcast from "./pages/podcast";
import Calendar from "./pages/calendar";
import Contact from "./pages/contact";

import './style.css';

// import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
// const client = new ApolloClient({
//   uri: "https://paipfsupportnetwork.org/paipfreact/",
//   cache: new InMemoryCache()
// });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="page" element={<BasePage />}>
            <Route path="support" element={<Support />} />
            <Route path="education" element={<Education />} />
            <Route path="podcast" element={<Podcast />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="contact" element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
