import React from 'react';
import { Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';

import InnerHTML from 'dangerously-set-html-content';

import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as Corner } from './images/corner.svg';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

// Detects if device is on iOS 
const mobileOS = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    // if (/windows phone/i.test(userAgent)) {
    //     return "windows";
    // }

    if (/android/i.test(userAgent)) {
        return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
    }

    return "default";
}
// Detects if device is in standalone mode
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

export default class App extends React.Component {
  constructor() {
    super();

    this.state = {
      showInstallMessage: false,
      installMessage: {
        'default': '<p>You can install this web app on your mobile device! Open your browser\'s menu, and click "Install" or "Add to Home Screen".</p>',
        'android': '<p>You can install this web app on your Android device! On newer devices, you may have already recieved an installation banner. If not, click your <i class="icon"></i> or <i class="icon"></i> button, then click "Install".</p>',
        'ios': '<p>You can install this web app on your Apple device! Click your <i class="icon"></i> button and then "Add to Home Screen".</p>'
      }
    };
  }
  componentDidMount() {
    // Checks if should display install popup notification:
    if (isMobile && !isInStandaloneMode()) {      
      this.setState({ showInstallMessage: true });
    }
  }

  optOut(){
    cookies.set('optout', true, { path: '/' });
    this.setState({ showInstallMessage: false });
  }

  render() {
    let showInstallMessage = this.state.showInstallMessage;
    let popup = false;
    if(showInstallMessage && !cookies.get('optout')){
      popup = (
        <div className="pwa-popup">
          <button title="Close" onClick={this.optOut.bind(this)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg></button>
          <InnerHTML html={this.state.installMessage[mobileOS()]} />
        </div>
      )
    }
    return (
      <div className="home">
        <header id="header-wrap" className="wrap">
          <div className="wrap-content">
            <h1 className="logo" title="Pennsylvania IPF Support Network" aria-label="Pennsylvania IPF Support Network">
              <Logo />
            </h1>
            <h2 className="welcome">Hello!</h2>
          </div>
        </header>
        <main id="main-wrap" className="wrap">
          <div className="wrap-content">
            <div className="hero-text">
              <h3>PA IPF Support Network</h3>
              <Link to="/page/contact/" className="btn icon-right-arrow-box">Contact Us!</Link>              
            </div>
            <nav className="block-nav">
              <Link to="/page/support/"><Corner /><strong>Find A Support Group</strong></Link>
              <Link to="/page/education/"><Corner /><strong>Educational Seminars</strong></Link>
              <Link to="/page/podcast/"><Corner /><strong>PA IPF Podcast Series</strong></Link>
              <Link to="/page/calendar/"><Corner /><strong>Check <br />Out Our Calendar</strong></Link>
            </nav>
          </div>
        </main>
        {popup}
      </div>
    );
  }
}